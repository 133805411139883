/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    var bp = {
        xs: 480,
        sm: 768,
        md: 992,
        lg: 1200
    };
    
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        // Mobile menu
        
        enquire.register('screen and (max-width: ' + (bp.md - 1) + 'px)', {
            setup: function(){
                // Sub-menus
                $('.menu-item-has-children').on('click.mobileMenu', function(e){
                    $(this).toggleClass('active');
                });
            },
            match: function(){
                // Menu
                $('#mobile-link-menu').on('click.mobileMenu', function(e){
                    e.preventDefault();
                    $('.nav-primary').slideToggle(300);
                });
                $('.nav-primary').hide();
                
                // Sub-menus
                $('.menu-item-has-children')
                    .off('mouseleave.mobileMenu')
                    .off('mouseenter.mobileMenu');
            },
            unmatch: function(){
                // Menu
                $('#mobile-link-menu').off('.mobileMenu');
                $('.nav-primary').show();
                
                // Sub-menus
                $('.menu-item-has-children')
                    //.off('.mobileMenu')
                    .removeClass('active');
            },
            deferSetup : false
        }).register('screen and (min-width: ' + bp.md + 'px)', {
            match: function(){
                // Sub-menus
                $('.menu-item-has-children')
                    .on('mouseleave.mobileMenu', function(){
                        $(this).removeClass('active');
                    })
                    .on('mouseenter.mobileMenu', function(){
                        $(this).addClass('active');
                    });
            }
        });
        
        
        // Match height pushs
        
        var sectionPushs = $('#page-pushs');
        if ( sectionPushs.length && ! sectionPushs.hasClass('full') ) {
            sectionPushs.find('.wikane-push .push-content').matchHeight();
            sectionPushs.find('.wikane-push .push-title').matchHeight();
        }
        
        
        // Push video
        
        if ( $('.js-push-link-video').length ) {
            $('.js-push-link-video').click(function(e){
                e.preventDefault();
                
                var target = $(this).attr('href');
                var entityParent = $(this).parents('.wikane-push').eq(0);
                var contentVideo = entityParent.find('.content-video');
                
                if ( target.indexOf('youtube.com') > -1 ) { // video YouTube
                    var containerVideo = $('<div class="media-youtube-video">');
                        containerVideo
                            .append('<iframe src="'+ target +'" width="'+ $(this).data('width') +'" height="'+ $(this).data('height') +'" frameborder="0" allowfullscreen />')
                            .appendTo(contentVideo)
                            .fitVids();
                } else { // video HTML5
                    contentVideo.find('video').get(0).play();
                }
                
                entityParent.find('> .inner').animate({opacity: 0}, 300, function(){
                    $(this).hide();
                    contentVideo.show();
                });
                
            });
        }
        
        
        // Masonry for posts archive
        
        var $gridMasonry = $('.isotope');
        if ( $gridMasonry.length ) {
            $gridMasonry.masonry({
                columnWidth: '.isotope-sizer',
                itemSelector: '.isotope-item',
                percentPosition: true
            });
            $gridMasonry.imagesLoaded().progress( function() {
                $gridMasonry.masonry('layout');
            });
        }
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
        
        
        if ( $('#home-slider').find('.slide').length > 1 ) {
            $('#wrapper-slider').find('> ul').bxSlider({
                pager: false
            });
        }
        
        
        if ( $('#home-carousel').length ) {
            
            var carousels = [];
            
            enquire.register('screen and (min-width: ' + bp.sm + 'px)', {
                setup: function(){
                    $('#home-carousel').find('.img-home-carousel-wrapper').each(function(index, el) {
                        var img = $(el).find('img');
                        if( img.data('src') !== undefined ){
                            img.attr('src', img.data('src'));
                            $(el).removeClass('no-picture');
                        }
                    });
                },
                match: function(){
                    carousels = [];
                    
                    $('.bxslider-carousel').each(function(){
                        if ( $(this).find('li').length > 1 ) {
                            var pause = 4000 + Math.floor(Math.random() * 3000);
                            
                            var slider = $(this).bxSlider({
                                auto: true,
                                infiniteLoop: true,
                                adaptiveHeight: false,
                                responsive: true,
                                pager: false,
                                controls: false,
                                pause: pause,
                                minSlides: 1
                            });
                            
                            carousels.push(slider);
                        }
                    });
                },
                unmatch: function(){
                    for (var i in carousels) {
                        carousels[i].destroySlider();
                    }
                },
                deferSetup : true
            }).register('screen and (min-width: ' + bp.lg + 'px)', {
                match: function(){
                    for (var i in carousels) {
                        if ( carousels[i].parents('.col1').length ) {
                            carousels[i].reloadSlider();
                        }
                    }
                },
                deferSetup : true
            });
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        
        // Animations
        
        var controller = new ScrollMagic.Controller();
        
        var sectionSliderId = '#home-slider';
        var sectionSlider = $( sectionSliderId );
        if ( sectionSlider.length ) {
            
            new ScrollMagic.Scene({ triggerElement: sectionSliderId, triggerHook: 0.7, reverse: false })
              .setTween(TweenMax.fromTo( sectionSlider.find('.slide-content'), '.7',
              { css: { top: 300, opacity: 0}, ease: Quad.easeOut, delay: 0 },
              { css: { top: 0, opacity: 1}, ease: Quad.easeOut, delay: 0 }
              ))
              .addTo(controller);
            
            new ScrollMagic.Scene({ triggerElement: sectionSliderId, triggerHook: 0.7, reverse: false })
              .setTween(TweenMax.fromTo( sectionSlider.find('.slide-link'), '.7',
              { css: { top: 300, opacity: 0}, ease: Quad.easeOut, delay: 0.3 },
              { css: { top: 0, opacity: 1}, ease: Quad.easeOut, delay: 0.3 }
              ))
              .addTo(controller);
        }
        
        var sectionAboutId = '#home-about';
        var sectionAbout = $( sectionAboutId );
        if ( sectionAbout.length ) {
            
            new ScrollMagic.Scene({ triggerElement: sectionAboutId, triggerHook: 0.7, reverse: false })
              .setTween(TweenMax.fromTo( sectionAbout.find('.col-sm-5'), '.7',
              { css: { left: -300, opacity: 0}, ease: Quad.easeOut, delay: 0 },
              { css: { left: 0, opacity: 1}, ease: Quad.easeOut, delay: 0 }
              ))
              .addTo(controller);
            
            new ScrollMagic.Scene({ triggerElement: sectionAboutId, triggerHook: 0.7, reverse: false })
              .setTween(TweenMax.fromTo( sectionAbout.find('.col-sm-7'), '.7',
              { css: { left: 300, opacity: 0}, ease: Quad.easeOut, delay: 0 },
              { css: { left: 0, opacity: 1}, ease: Quad.easeOut, delay: 0 }
              ))
              .addTo(controller);
        }
        
        var sectionPostId = '#home-post';
        var sectionPost = $( sectionPostId );
        if ( sectionPost.length ) {
            
            new ScrollMagic.Scene({ triggerElement: sectionPostId, triggerHook: 0.7, reverse: false })
              .setTween(TweenMax.fromTo( sectionPost.find('.col-sm-2'), '.7',
              { css: { left: -300, opacity: 0}, ease: Quad.easeOut, delay: 0.3 },
              { css: { left: 0, opacity: 1}, ease: Quad.easeOut, delay: 0.3 }
              ))
              .addTo(controller);
            
            new ScrollMagic.Scene({ triggerElement: sectionPostId, triggerHook: 0.7, reverse: false })
              .setTween(TweenMax.fromTo( sectionPost.find('.col-sm-4'), '.7',
              { css: { left: -300, opacity: 0}, ease: Quad.easeOut, delay: 0 },
              { css: { left: 0, opacity: 1}, ease: Quad.easeOut, delay: 0 }
              ))
              .addTo(controller);
            
            new ScrollMagic.Scene({ triggerElement: sectionPostId, triggerHook: 0.7, reverse: false })
              .setTween(TweenMax.fromTo( sectionPost.find('.col-sm-6'), '.7',
              { css: { left: 300, opacity: 0}, ease: Quad.easeOut, delay: 0 },
              { css: { left: 0, opacity: 1}, ease: Quad.easeOut, delay: 0 }
              ))
              .addTo(controller);
        }
        
        enquire.register('screen and (min-width: ' + bp.md + 'px)', {
            setup: function(){},
            match: function(){
                var sectionMagId = '#home-mag';
                var sectionMag = $( sectionMagId );
                if ( sectionMag.length ) {
                    
                    new ScrollMagic.Scene({ triggerElement: sectionMagId, triggerHook: 0.7, reverse: false })
                      .setTween(TweenMax.fromTo( sectionMag.find('.block-header'), '.7',
                      { css: { marginTop: 300, opacity: 0}, ease: Quad.easeOut, delay: 0 },
                      { css: { marginTop: 0, opacity: 1}, ease: Quad.easeOut, delay: 0 }
                      ))
                      .addTo(controller);
                }
            },
            unmatch: function(){},
            deferSetup : false
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    
    'page_template_page_leviers': {
      init: function() {
          
          // Smooth scroll when click on icons
          
          $('#wrapper-leviers-anchors').find('a').on('click', function(e){
              e.preventDefault();
              var elementId = $(this).attr('href');
              $('html, body').animate({scrollTop: $(elementId).offset().top - 30}, 300, function(){
                  window.location.hash = elementId;
              });
          });
          
          // Animations
          
          var controller = new ScrollMagic.Controller();
          
          $('.services-boxes').each(function(){
              var id = $(this).attr('id');
              
              new ScrollMagic.Scene({ triggerElement: '#' + id, triggerHook: 0.7, reverse: false })
                  .setTween(TweenMax.fromTo('#' + id, '.7',
                  { css: { left: ( $(this).hasClass('icon-right') ? -300 : 300 ), opacity: 0}, ease: Quad.easeOut, delay: 0 },
                  { css: { left: 0, opacity: 1}, ease: Quad.easeOut, delay: 0 }
                  ))
                  .addTo(controller);
          });
      }
    },
    
    'post_type_archive_prestation': {
        init: function(){
            
            // Animations
            
            var controller = new ScrollMagic.Controller();
            
            new ScrollMagic.Scene({ triggerElement: '#prestation-listing-title', triggerHook: 0.7, reverse: false })
                .setTween(TweenMax.to('#prestation-listing-wrapper', 0,
                { className: '-=closed' }
                ))
                .addTo(controller);
        }
    },
    
    'page_template_page_faq': {
        init: function(){
            $('.faq-item .question').click(function(){
                var item = $(this).parent();
                    item
                        .toggleClass('active')
                        .find('.reponse')
                            .slideToggle();
                
            });
        }
    },
    
    'page_template_page_temoignages': {
        init: function(){
            if ( $('.temoignages-item').length ) {
                $('.temoignages-item .content').matchHeight();
            }
        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
